<template>
  <div>
    <h2 class="c-view-h2">{{$store.state.event.name}}</h2>
    <h1 class="c-view-h1">Teilnehmer</h1>
    <ui-toolbar :actions="toolbar"></ui-toolbar>
    <div class="">
      <ui-table :data="filtered" :columns="columns" :actions="actions" :pageSize="25" />
    </div>
  </div>
  <edit-attendee ref="editAttendee" />
  <new-filter ref="newFilter" />
</template>

<script>

import EditAttendee from "@/components/Admin/dialogs/EditAttendee";
import NewFilter from "@/components/Admin/modals/NewFilter";

export default {
  name: "Attendees",
  components: {
    EditAttendee,
    NewFilter
  },
  data(){
    return {
      columns:[
        {
          prop: 'mwId',
          label: 'ID',
          width: 6
        },
        {
          prop: '2',
          label: 'Anrede'
        },
        {
          prop: '3',
          label: 'Vorname'
        },
        {
          prop: '4',
          label: 'Nachname'
        },
        {
          prop: '1',
          label: 'e-Mail'
        }
      ],
      actions: [
        {
          icon: 'fa-edit',
          action: (attendee) => this.$refs.editAttendee.open(attendee),
          title: 'Bearbeiten'
        }
      ],
      toolbar: [
        {
          title: 'Filter',
          label: `<i class="fa fa-filter"></i>`,
          component: 'button',
          action: () => this.$refs.newFilter.open()
        },
        {
          title: 'Export',
          label: `<i class="fa fa-file-export"></i>`,
          component: 'button',
          action: () => console.log('export')
        }
      ]
    }
  },
  async mounted() {
    console.log(this.$store.state.event)
    await this.$store.getRecipients()
    await this.$store.getTickets()
  },
  computed: {
    search: {
      get() {
        return this.$env.searchField
      },
      set(value) {
        this.$env.searchField = value
      }
    },
    richRecipients(){
      let ret = this.$store.state.recipients
      ret.map(r => {
        r.ticket = this.$store.state.tickets.find(t => Number(t.mwSubscriberId) === Number(r.mwId))
      })
      return ret
    },
    filtered() {
      let origin = [], filtered = []

      // if(!Array.isArray(this.$root.users)) return []
      origin = this.richRecipients

      // if (this.filters.length > 0) {
      //   origin = origin.filter(user => {
      //     let matches = 0
      //     this.filters.forEach(f => {
      //       let result = this.getOperator(f.name,f.operator).filter(this.$root.event, user, f.entity, f.value)
      //       if(result) matches++
      //       return result
      //     })
      //     if(this.filterMode === 'and' && matches === this.filters.length) return true
      //     if(this.filterMode === 'andOr' && matches > 0) return true
      //     if(this.filterMode === 'or' && matches === 1) return true
      //     return false
      //   })
      // }

      if (this.search == '') {
        return origin
      }
      if (this.search.length < 3) {
        return origin
      }
      const patt = new RegExp(this.search, "i")
      filtered = origin.filter(entry => {
        let found = false
        this.columns.forEach(c => found = patt.test(entry[c.prop]) ? true : found)
        console.log(found)
        return found
      })
      return filtered
    }
  }
}
</script>

<style scoped>

</style>
