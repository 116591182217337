<template>
  <ui-dialog title="Teilnehmer bearbeiten" ref="dialog" @confirm="confirm()" size="wide" confirmLabel="Speichern">
    <ui-field label="Name">
      <p>
        {{attendee['3']}} {{attendee['4']}}
      </p>
    </ui-field>
    <ui-buttons>
      <ui-button @click="editAttendee(attendee['mwId'])">In {{$env.whiteLabel.mailProduct}} bearbeiten</ui-button>
    </ui-buttons>
  </ui-dialog>
</template>

<script>
import {getSID} from "@/store/auth";

export default {
  name: "EditAttendee",
  data(){
    return {
      attendee: {}
    }
  },
  methods: {
    async confirm(){
      return true
    },
    async open(a){
      this.attendee = Object.assign({}, a)
      console.log(a)
      this.$refs.dialog.open()
    },editAttendee(id){
      if(typeof id !== 'undefined'){
        let link = this.$env.whiteLabel.mailDomain + 'subscribermanager/subscribermanager/getUpdateSubscriber/'+ id +'?sid='+ getSID()
        window.open(link);
        return true
      }
      return false
    }
  },
  computed: {

  }
}
</script>

<style scoped>

</style>